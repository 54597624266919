@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: url("./Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: url("./Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}