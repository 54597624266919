@import url("./fonts/NeueMontreal/font.css");
@import url("./fonts/Roboto/font.css");
@import url("./fonts/Inter/font.css");
@import url("./fonts/Poppins/font.css");
@import url("./fonts/Work_Sans/font.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100vh;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #e6e3dc;
  }

  &::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

html {
  font-size: 20px;
  color: black;
}

* {
  font-family: "Roboto", sans-serif;
}
