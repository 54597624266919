@font-face {
  font-family: "Inter";
  src: url("./Inter-VariableFont_opsz,wght.ttf")
    format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-ExtraLight.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./static/Inter_18pt-Thin.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}